import React, {Suspense} from 'react';
import {AppRoutes} from "./AppRoutes";
import Loader from "react-loaders";
import {connect} from "react-redux";
import LoadingOverlayWrapper from "react-loading-overlay-ts";
import {getThemeClass} from "./data/config/theme-class";

function App(props) {
  const {isLoading} = props;
  const themeClass = getThemeClass();
  return (
      <Suspense fallback={
          <div className={`loader-container`}>
              <div className="loader-container-inner">
                  <h6 className="mt-5">
                      <Loader type="line-scale" active/>
                  </h6>
              </div>
          </div>
      }>
          <LoadingOverlayWrapper
              active={isLoading}
              spinner={
                  <Loader type="line-scale" active/>
              }>
              <div className={themeClass}>
                  <AppRoutes/>
              </div>
          </LoadingOverlayWrapper>
      </Suspense>
  );
}

const mapStateToProps = state => ({
   isLoading: state.AppLoading.isLoading,
});
const mapDispatchToProps = dispatch => ({});
export default connect(mapStateToProps, mapDispatchToProps)(App);
