import {Helmet} from "react-helmet";
import {BusinessPath} from "../../data/maps/business-path";

export const Headers = () => {
    const business_path = BusinessPath[process.env.REACT_APP_BUSINESS_ID];
    return (
        <Helmet>
            <link rel="icon" href={`${business_path}/favicon.ico`} />
            <link rel="apple-touch-icon" href={`${business_path}/logo192.png`} />
            <link rel="manifest" href={`${business_path}/manifest.json`} />
        </Helmet>
    );
}