import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './app/App';
import reportWebVitals from './reportWebVitals';
import {BrowserRouter, HashRouter} from "react-router-dom";
import {Provider} from "react-redux";
import configureStore from "./app/data/store/configureStore";
import './i18n';
import "./polyfills";
import "./assets/base.scss";
import {Headers} from "./app/components/core/headers";

const store = configureStore();

ReactDOM.render(
    <Provider store={store}>
        <HashRouter>
            <Headers></Headers>
            <App />
        </HashRouter>
    </Provider>,
    document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
