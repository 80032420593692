import React from "react";
import {Col, Container, Row} from "reactstrap";
import {setIsLoading} from "../../data/store/reducers/AppLoading";
import {connect} from "react-redux";
import {useTranslation} from "react-i18next";
import {BusinessPath} from "../../data/maps/business-path";


const OutOfServiceSection = (props) => {

    const {setIsLoading} = props;
    setIsLoading(false);

    const {t} = useTranslation();
    const business_path = BusinessPath[process.env.REACT_APP_BUSINESS_ID];

    return (
        <>
            <Container fluid className={"app-form-section"}>
                <Row>
                    <Col md={{offset: 1, size: 10}}
                         xl={{offset: 2, size: 8}}>
                        <hr/>
                        <Row>
                            <Col md="12" className={"mb-2"}>
                                <p className={"text-center title"}>{t('translation:LABEL.EMAIL_FORM')}</p>
                                <p className={"text-center info"}>Thank you for your support. At the moment it is not necessary to send more emails.</p>
                                <p className={"text-center info"}>Gracias por su apoyo. Por el momento no es necesario enviar más correos.</p>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={"12"} className={"image-container"}>
                                <img src={`${business_path}/logo192.png`} alt=""/>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
        </>
    );
};

const mapStateToProps = state => ({});
const mapDispatchToProps = dispatch => ({
    setIsLoading: isLoading => dispatch(setIsLoading(isLoading))
});
export default connect(mapStateToProps, mapDispatchToProps)(OutOfServiceSection);
