import React, {Fragment} from "react";
import {Container} from "reactstrap";

export const MainContent = (props) => {
    const {children} = props;

    return (
        <Fragment>
            <Container fluid className={"app-main-content"}>
                {children}
            </Container>
        </Fragment>
    );
};
