import {Fragment} from "react";
import {Container} from "reactstrap";

export const Footer = () => {
  return (
    <Fragment>
        <Container fluid className="app-footer">
            <hr/>
        </Container>
    </Fragment>
  );
}