import React from "react";
import {Route, Routes} from "react-router-dom";
import {ContactUsView} from "./views/ContactUsView";
import {SimpleContainer} from "./components/containers/SimpleContainer";

export const AppRoutes = () => (
    <Routes>
        <Route path="*" element={
            <SimpleContainer>
                <Routes>
                    <Route path="/4fc60861-c033-4d56-aa65-f45871ba52dd" element={<ContactUsView userCC={false} />}/>
                    <Route path="/*" element={<ContactUsView />} />
                </Routes>
            </SimpleContainer>
        } />
    </Routes>
);
