export const SET_LOADING = 'APP_LOADING/SET_LOADING';

export const setIsLoading = isLoading => ({
    type: SET_LOADING,
    isLoading
});

export default function reducer(state = {
    isLoading: true,
}, action) {
    switch (action.type) {
        case SET_LOADING:
            return {
                ...state,
                isLoading: action.isLoading
            };
        default:
    }
    return state;
}